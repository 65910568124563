const Footer = () => {
    return (
        <>
            {/* Footer */}
            <div id="footer_center">
                <div id="footer_wrapper">
                    <div className="top_footer">
                    </div>
                    <div className="bottom_footer">
                        Copyright © 2012. Homeopathic Health Care. All Rights Reserved. Test <span>
                            By Dr Neha Pujara<br />
                            {/* Visitors :
                            109935 */}
                        </span>
                    </div>
                </div>
            </div>
            {/* End Footer */}
        </>
    );
};

export default Footer;
