//import ImageSlider from "../components/ImageSlider";
import { useEffect, useRef, useState } from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { Link } from 'react-router-dom';
import { getPageContent } from '../services/pages.service';
import { HelmetProvider } from 'react-helmet-async';



const Home = () => {

    const carouselRef = useRef<any>(null);
    const [htmlElement, setHtmlElement] = useState<any>(null);

    useEffect(() => {
        getPageContent('Home').then((response) => {
            if (response.status === 200) {
                setHtmlElement(response.data.data?.PageHTML);
            }
        });

        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key === 'ArrowLeft') {
                carouselRef.current.slidePrev();
            } else if (e.key === 'ArrowRight') {
                carouselRef.current.slideNext();
            }
        };
        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const handleNextButton = (e: React.MouseEvent) => {
        e.preventDefault();
        carouselRef.current.slideNext();
    };

    const handlePrevButton = (e: React.MouseEvent) => {
        e.preventDefault();
        carouselRef.current.slidePrev();
    };

    const handleDragStart = (e: { preventDefault: () => any; }) => e.preventDefault();

    const items = [
        <img src={require("./../assets/images/SliderImages/MySlider/tooltips/hh_01.png")} alt="001" onDragStart={handleDragStart} role="presentation" />,
        <img src={require("./../assets/images/SliderImages/MySlider/tooltips/hh_02.png")} alt="002" onDragStart={handleDragStart} role="presentation" />,
        <img src={require("./../assets/images/SliderImages/MySlider/tooltips/hh_03.png")} alt="003" onDragStart={handleDragStart} role="presentation" />,
        <img src={require("./../assets/images/SliderImages/MySlider/tooltips/hh_04.png")} alt="004" onDragStart={handleDragStart} role="presentation" />,
        <img src={require("./../assets/images/SliderImages/MySlider/tooltips/hh_05.png")} alt="005" onDragStart={handleDragStart} role="presentation" />,
        <img src={require("./../assets/images/SliderImages/MySlider/tooltips/hh_06.png")} alt="006" onDragStart={handleDragStart} role="presentation" />,
        <img src={require("./../assets/images/SliderImages/MySlider/tooltips/hh_07.png")} alt="007" onDragStart={handleDragStart} role="presentation" />,
        // Add more items as needed
    ];

    return (
        <>
            <HelmetProvider>
                <title>Holistic Health Care | Homeopathic Doctor in Ahmedabad</title>
                <meta name="keywords" content="holistic health care, healing, Homeopathic doctor in ahmedabad, holistic healing, healing with homeopathy, homoeopathic cases,holistic health" />
                <meta name="description" content="Dr Neha Pujara is an efficient, matured homeopathic doctor in ahmedabad.She has a vast experience of 15 years in homeopathy treatment.She is running homoeopathic clinic in Ahmedabad named Holistic Health Care." />

            </HelmetProvider>
            <div className="sliderImages">
                <AliceCarousel
                    infinite={true}
                    items={items}
                    autoPlay={true} // Enable autoplay
                    autoPlayInterval={5000}
                    controlsStrategy="responsive" // Show controls only when responsive items are greater than 1
                    ref={carouselRef}
                    renderPrevButton={() => {
                        return <span onClick={handlePrevButton} className="prevArrow">
                            <img
                                src={require("./../assets/images/circle-chevron-left-solid.png")}
                                alt="Previous"
                                style={{ width: '20px', height: '20px' }} // Customize image size as needed
                            />
                        </span>
                    }}

                    renderNextButton={() => (
                        <span onClick={handleNextButton} className="nextArrow">
                            <img
                                src={require("./../assets/images/circle-chevron-right-solid.png")}
                                alt="Next"
                                style={{ width: '20px', height: '20px' }} // Customize image size as needed
                            />
                        </span>
                    )}
                />
            </div>
            <div className=".cleaner">
            </div>

            <div id="content">
                <div>
                    <br />
                    <h1 className='health_care_content'>
                        Holistic Health Care - Homeopathic Treatment Center In Ahmedabad
                        <Link to={"//plus.google.com/118373359896932658214?prsrc=3"} target="_blank" rel="">
                            <img src="//ssl.gstatic.com/images/icons/gplus-32.png" alt="Google+" />
                        </Link>
                    </h1>
                    <hr />

                    {htmlElement && (
                        <p dangerouslySetInnerHTML={{ __html: htmlElement }}></p>
                    )}
                </div>
            </div>
        </>
    )

};
export default Home;