import { Navigate, Outlet, useLocation } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import HeaderMenu from "../components/HeaderMenu";
import HeaderMobileMenu from "../components/HeaderMobileMenu";
import { useSelector } from "react-redux";
import { getToken } from "../store/selector/authSelector";

const PrivateRoute = () => {

  const location = useLocation();
  const authenticated = useSelector(getToken);
  //const authenticated =false;

  if (!authenticated) {
    return <Navigate to={"/login"} state={{ from: location }} replace />;
  }
  return (
    <main id="main">
      <HeaderMobileMenu />
      <Header />
      <HeaderMenu />

      <div id="Div1">
        <div id="content_wrapper_inner">
          <Outlet />
        </div>
      </div>

      <Footer />
    </main>
  );
};
export default PrivateRoute;