//import ImageSlider from "../components/ImageSlider";
import { useEffect, useState } from 'react';
import { getPageContent } from '../../services/pages.service';
import { HelmetProvider } from 'react-helmet-async';

const ThingsToKeepInMind = () => {
    const [htmlElement, setHtmlElement] = useState<any>(null);
    useEffect(() => {
        getPageContent('Things To Keep In Mind').then((response) => {
            if (response.status === 200) {
                setHtmlElement(response.data.data?.PageHTML);
            }
        });
    }, []);

    return (
        <>

            <HelmetProvider>
                <title>
                    Holistic Health Care - Things To Keep In Mind In Homeopathic Treatment
                </title>
                <meta name="keywords" content="holistic health care, healing, dr in ahmedabad, holistic healing, dr homeo, homeopathy doctor, homeopathic materia medica, natural health care, naturopathic remedies, healing with homeopathy, homoeopathic cases, homeo clinic , holistic health, Arthritis doctor ahmedabad, child homeopathy doctor" />
                <meta name="description" content="Dr Neha Pujara is a matured homoeopathic doctor in ahmedabad. She has written some Things To Keep In Mind for the homeopathic patient." />
            </HelmetProvider>
            <div id="content_wrapper_inner">
                <div id="content" className="home">
                    <div>
                        <br />
                        <h1 className='health_care_content'>
                            Things To Keep In Mind
                        </h1>
                        <hr />
                        <br />
                        <br />
                        {htmlElement && (
                            <p dangerouslySetInnerHTML={{ __html: htmlElement }}></p>
                        )}
                    </div>
                </div>
            </div>
        </>
    )

};
export default ThingsToKeepInMind;