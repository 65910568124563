import { useEffect, useState } from "react";
import DropdownMenu from "./DropdownMenu";
import { Link, useLocation } from "react-router-dom";
import { getHeaderMenu } from "../services/pages.service";

const HeaderMobileMenu = () => {

    const [isMenuOpen, setMenuOpen] = useState(false);
    const location = useLocation();

    const [visibleDropdowns, setVisibleDropdowns] = useState<{ [key: string]: boolean }>({});
    const [parentId, setParentId] = useState(0);
    //= 
    const [menu, setMenu] = useState<string[]>([]);
    const [pageUrl, setPageUrl] = useState<string>('');

    useEffect(() => {
        getHeaderMenu().then((response) => {
            if (response.status === 200) {
                setMenu(response.data.data);
            }
        });
    }, []);

    const handleMouseEnter = (id: string) => {
        setVisibleDropdowns({ ...visibleDropdowns, [id]: true });
    };
    const handleMouseLeave = (id: string) => {
        setVisibleDropdowns({ ...visibleDropdowns, [id]: false });
    };

    const handleClick = (element: any) => {
        setPageUrl(element.pageUrl);
        if ('children' in element) {
        } else {
            setMenuOpen(!isMenuOpen);
        }
    }

    useEffect(() => {
        if (location.pathname === pageUrl) {
            setParentId(0);
        }
    }, [location.pathname, pageUrl]);

    const subMenuData = (parentId: number) => {
        setParentId(parentId);
        setMenuOpen(!isMenuOpen);
    };

    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen);
    };

    return (
        <>
            <div className="mobile_menu">
                <button onClick={toggleMenu} className="toggle-button">
                    {/* {isMenuOpen ? '✕' : '☰'} */}
                    {'☰'}
                </button>
                <div className={`sidebar ${isMenuOpen ? 'open' : ''}`}>
                    <button onClick={toggleMenu} className="toggle-button close">
                        {'✕'}
                    </button>

                    <ul>
                        {menu.map((element: any, index: number) => (
                            <li key={element.id}
                                onClick={() => handleClick(element)}
                                className={`${'children' in element ? 'dropdown' : ''} ${parentId === element.id || location.pathname === element.pageUrl ? ' selected default' : ''}`}
                                onMouseEnter={() => handleMouseEnter(element.id)}
                                onMouseLeave={() => handleMouseLeave(element.id)}>
                                <Link className={`main-menu ${'children' in element ? 'main-menu-title' : ''}`} to={'children' in element ? '' : element.pageUrl} title={element.name}>
                                    {element.name}
                                </Link>
                                {(visibleDropdowns[element.id] && 'children' in element) && <DropdownMenu subMenuData={subMenuData} items={element.children} />}
                            </li>
                        ))}

                    </ul>
                </div>

            </div>
        </>
    );
};

export default HeaderMobileMenu;

