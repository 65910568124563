import "./assets/css/custom.css";
import "./assets/css/dropdowntabfiles/ddcolortabs.css";
import "./assets/css/dropdown.css";

import AppRoutes from "./navigation/AppRouter";
import { useEffect } from "react";
import { initGA, logPageView } from "./components/logPageView";
import { Provider } from "react-redux";
import store from "./store/store";

function App() {

  useEffect(() => {
    // Initialize Google Analytics
    initGA();

    // Log the initial page view
    logPageView();
  }, []);

  return (
    <div className="App">
      <Provider store={store}>
        <AppRoutes />
      </Provider>
    </div>
  );
}

export default App;
