import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
/* import { Bold, Italic } from '@ckeditor/ckeditor5-basic-styles';
import { Essentials } from '@ckeditor/ckeditor5-essentials';
import { Paragraph } from '@ckeditor/ckeditor5-paragraph'; */


interface CKEditorProps {
    data: string;
    onChange: (data: string) => void;
    placeholder: string;
}

const CKEditorComponent: React.FC<CKEditorProps> = ({ data, onChange, placeholder }) => {
    return (
        <>
            <CKEditor
                //config={config}
                config={{
                    toolbar: ['undo', 'redo', 'heading', '|', 'bold', 'italic', 'link', 'insertTable', 'bulletedList', 'numberedList', 'blockQuote'],
                    placeholder: placeholder,
                }}
                editor={ClassicEditor}
                data={data}
                /* onReady={editor => {
                    // You can store the "editor" and use when it is needed.
                    console.log('Editor is ready to use!', editor);
                }} */
                onChange={(event, editor) => {
                    const data = editor.getData();
                    onChange(data);
                }}
            /* onBlur={(event, editor) => {
                console.log('Blur.', editor);
            }}
            onFocus={(event, editor) => {
                console.log('Focus.', editor);
            }} */
            />
        </>
    );
};

export default CKEditorComponent;
