import { Outlet } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import HeaderMenu from "../components/HeaderMenu";
import HeaderMobileMenu from "../components/HeaderMobileMenu";
const PublicRoute = () => {

    return (
        <main id="main">
            <HeaderMobileMenu />
            <Header />
            <HeaderMenu />
            
            <div id="Div1">
                <div id="content_wrapper_inner">
                    <Outlet />
                </div>
            </div>

            <Footer />
        </main>
    );
};
export default PublicRoute;

