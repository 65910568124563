import { SubmitHandler, useForm } from "react-hook-form";
import { addQueries } from "../../services/pages.service";
import { useState } from "react";
import { HelmetProvider } from "react-helmet-async";

const Queries = () => {

    const [successResponseMessage, setSuccessResponseMessage] = useState('');
    const [errorResponseMessage, setErrorResponseMessage] = useState('');
    type FormData = {
        firstName: string;
        lastName: string;
        email: string;
        city: string;
        state: string;
        country: string;
        textmessage: string;
    };
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<FormData>();

    const handleResetInputValues = () => {
        Array.from(document.querySelectorAll("input")).forEach(
            input => (input.value = "")
        );
    };

    const onSubmit: SubmitHandler<FormData> = (data) => {

        addQueries(data).then((res) => {
            if (res.status === 200) {
                setSuccessResponseMessage(res.data.message);
                handleResetInputValues();
            }
            if (res.status === 409) {
                setErrorResponseMessage(res.data.message)
            }
        });
    };

    return (
        <>
            <HelmetProvider>
                <title>
                    Holistic Health Care - Homeopathic Treatment Queries
                </title>
                <meta name="keywords" content="holistic health care, homeopathic queries, dr in ahmedabad, holistic healing, dr homeo, homeopathy doctor, homeopathic materia medica, natural health care, naturopathic remedies, healing with homeopathy, homoeopathic cases, homeo clinic , holistic health, Arthritis doctor ahmedabad, child homeopathy doctor" />
                <meta name="description" content="Dr Neha Pujara is a matured homoeopathic doctor.Homeopathic Patient any where in the world can post there homoeopathic query to her by providing basic detail." />
            </HelmetProvider>
            <div id="content_wrapper_inner">
                <div id="content" className="home">
                    <h1 className="fs-22">
                        Queries</h1>
                    <p className="queryP">Please do write to us in case you have any queries and we shall revert to you on
                        the same in 2 working days.</p>
                    <hr />
                    <br />
                    {errorResponseMessage && <p className="compulsary">{errorResponseMessage}</p>}
                    {successResponseMessage && <p className="text-success">{successResponseMessage}</p>}
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <table className="queryForm">
                            <tbody>
                                <tr>

                                </tr>

                                <tr>
                                    <td>
                                        First Name
                                        <span className="compulsary">*</span>
                                        <p></p>
                                    </td>
                                    <td>
                                        <input
                                            {...register("firstName", { required: "Please enter your First name." })}
                                        />
                                        {errors.firstName && <p className="compulsary">{errors.firstName.message}</p>}
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        Last Name
                                        <span className="compulsary">*</span>
                                        <p></p>
                                    </td>
                                    <td>
                                        <input
                                            {...register("lastName", { required: "Please enter your Last name." })}
                                        />
                                        {errors.lastName && <p className="compulsary">{errors.lastName.message}</p>}
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        Email ID
                                        <span className="compulsary">*</span>
                                        <p></p>
                                    </td>
                                    <td>
                                        <input
                                            {...register("email", {
                                                required: "Please enter your Email.",
                                                validate: {
                                                    matchPattern: (v) =>
                                                        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                                                        "Email address must be a valid address",
                                                },
                                            })}
                                        />
                                        {errors.email && <p className="compulsary">{errors.email.message}</p>}

                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        City
                                        <p></p>
                                    </td>
                                    <td>
                                        <input type="textbox" {...register("city")} />
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        State
                                        <p></p>
                                    </td>
                                    <td>
                                        <input type="textbox"  {...register("state")} />
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        Country
                                        <p></p>
                                    </td>
                                    <td>
                                        <input type="textbox" {...register("country")} />
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        Message
                                        <p></p>
                                    </td>
                                    <td>
                                        <textarea
                                            cols={20}
                                            rows={5}
                                            {...register("textmessage", {
                                                maxLength: {
                                                    value: 200,
                                                    message: 'Message must not exceed 200 characters',
                                                },
                                            })}>
                                        </textarea>
                                        {errors.textmessage && <p className="compulsary">{errors.textmessage.message}</p>}
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        &nbsp;
                                    </td>
                                    <td>
                                        <div className="button_02">
                                            <button type="submit" className="btnSubmit">Submit</button>
                                        </div>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </form>

                </div>
            </div>
        </>
    )

};
export default Queries;