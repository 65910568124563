//import ImageSlider from "../components/ImageSlider";
import { useEffect, useState } from 'react';
import { getPageContent } from '../../services/pages.service';
import { HelmetProvider } from 'react-helmet-async';

const SourcesOfHomoeopathicMedicines = () => {
    const [htmlElement, setHtmlElement] = useState<any>(null);
    useEffect(() => {
        getPageContent('Sources Of Homoeopathic Medicines').then((response) => {
            if (response.status === 200) {
                setHtmlElement(response.data.data?.PageHTML);
            }
        });
    }, []);

    return (
        <>
            <HelmetProvider>
                <title>
                    Sources of Homeopathic Medicines
                </title>
                <meta name="keywords" content="holistic health care, Preparation of Homeopathic Medicines, dr in ahmedabad, holistic healing, dr homeo, homeopathy doctor, homeopathic materia medica, natural health care, naturopathic remedies, healing with homeopathy, homoeopathic cases, homeo clinic , holistic health, Arthritis doctor ahmedabad, child homeopathy doctor" />
                <meta name="description" content="Dr Neha Pujara is an efficient homeopathic doctor.She has done basic study in different Sources of Homeopathic Medicines." />
            </HelmetProvider>
            <div id="content_wrapper_inner">
                <div id="content" className="home">
                    <div>
                        <br />
                        <h1 className='health_care_content'>
                            Sources Of Homoeopathic Medicines
                        </h1>
                        <hr />
                        <br />

                        <div className="sourceofHomeopathicMedicineImage">
                            <img src={require("../../assets/images/HomeoSource.png")} alt="Home Source" />
                            <br />
                            <b>Sources of Homeopathic Medicines</b>
                        </div>
                        <div className="holisticeScienceContent">
                            <br />
                            {htmlElement && (
                                <p dangerouslySetInnerHTML={{ __html: htmlElement }}></p>
                            )}
                            <br />

                        </div>


                    </div>
                </div>
            </div>
        </>
    )

};
export default SourcesOfHomoeopathicMedicines;