import { Link, useLocation } from "react-router-dom";
import DropdownMenu from "./DropdownMenu";
import { useEffect, useState } from "react";
import { getHeaderMenu } from "../services/pages.service";

const HeaderMenu = () => {

    const location = useLocation();

    const [visibleDropdowns, setVisibleDropdowns] = useState<{ [key: string]: boolean }>({});
    const [parentId, setParentId] = useState(0);
    
    const [menu, setMenu] = useState<string[]>([]);
    const [pageUrl, setPageUrl] = useState<string>('');
    useEffect(() => {
        getHeaderMenu().then((response) => {
            if (response.status === 200) {
                setMenu(response.data.data);
            }
        });
    }, []);

    const handleMouseEnter = (id: string) => {
        setVisibleDropdowns({ ...visibleDropdowns, [id]: true });
    };
    const handleMouseLeave = (id: string) => {
        setVisibleDropdowns({ ...visibleDropdowns, [id]: false });
    };

    const handleClick = (pageUrl: string) => {
        setPageUrl(pageUrl);
    }

    useEffect(() => {
        if (location.pathname === pageUrl) {
            setParentId(0);
        }
    }, [location.pathname, pageUrl]);

    const subMenuData = (parentId: number) => {
        setParentId(parentId);
        setVisibleDropdowns({ ...visibleDropdowns, [parentId]: false });
    };

    return (
        <>
            {/* Header Menu */}
            <div id="content_wrapper_outer" className="main_menu">
                <div className="header-menu">
                    <div id="colortab" className="ddcolortabs">
                        <ul>
                            {menu.map((element: any, index: number) => (

                                <li key={element.id}
                                    onClick={() => handleClick(element.pageUrl)}
                                    className={`${'children' in element ? 'dropdown' : ''} ${parentId === element.id || location.pathname === element.pageUrl ? ' selected default' : ''}`}
                                    onMouseEnter={() => handleMouseEnter(element.id)}
                                    onMouseLeave={() => handleMouseLeave(element.id)}>
                                    <Link className={`${'children' in element ? 'main-menu-title' : ''}`} to={'children' in element ? '' : element.pageUrl} title={element.name}>
                                        <span>{element.name}</span>
                                    </Link>
                                    {(visibleDropdowns[element.id] && 'children' in element) && <DropdownMenu subMenuData={subMenuData} items={element.children} />}
                                </li>
                            ))}

                        </ul>
                    </div>
                    <div className="cleaner">
                    </div>
                </div>
            </div>
            {/* End Header Menu */}
        </>
    );
};

export default HeaderMenu;
