import { Link } from "react-router-dom";

interface MenuProps {
    items: any;
    subMenuData: any;
}

const DropdownMenu = (props: MenuProps) => {


    const handleClick = (parentId: any) => {
        return props.subMenuData(parentId);
    };
    
    return (
        <>
            <div className="dropdown-content">
                {props.items.map((element: any, index: number) => (
                    <Link key={element.id} onClick={() => handleClick(element.parentId)} to={element.pageUrl}>{element.name}</Link>
                ))}

            </div>
        </>
    );
};

export default DropdownMenu;

