//import ImageSlider from "../components/ImageSlider";
import { useEffect, useState } from 'react';
import { getPageContent } from '../../services/pages.service';
import { HelmetProvider } from 'react-helmet-async';

const HowDoesHomeopathyWork = () => {
    const [htmlElement, setHtmlElement] = useState<any>(null);
    useEffect(() => {
        getPageContent('How Does Homoeopathy Work').then((response) => {
            if (response.status === 200) {
                setHtmlElement(response.data.data?.PageHTML);
            }
        });
    }, []);

    return (
        <>
            <HelmetProvider>
                <title>
                    How Does Homeopathy Work
                </title>
                <meta name="keywords" content="holistic health care, How Does Homeopathy Work, dr in ahmedabad, holistic healing, dr homeo, homeopathy doctor, homeopathic materia medica, natural health care, naturopathic remedies, healing with homeopathy, homoeopathic cases, homeo clinic , holistic health, Arthritis doctor ahmedabad, child homeopathy doctor" />
                <meta name="description" content="Dr Neha Pujara is an efficient consultant in homeopathy.She has vast experience in understanding the way Homeopathy Work." />
            </HelmetProvider>
            <div id="content_wrapper_inner">
                <div id="content" className="home">
                    <div>
                        <br />
                        <h1 className='health_care_content'>
                            How Does Homoeopathy Work
                        </h1>
                        <hr />
                        <br />
                        <br />
                        {htmlElement && (
                            <p dangerouslySetInnerHTML={{ __html: htmlElement }}></p>
                        )}
                    </div>
                </div>
            </div>
        </>
    )

};
export default HowDoesHomeopathyWork;