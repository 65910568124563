import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { HelmetProvider } from "react-helmet-async";
import { useNavigate } from "react-router";
import Select from "react-select";
import { getPageNames, saveMenuContent } from "../../services/pages.service";
import CKEditorComponent from "../../components/CKEditorComponent";

const AdminPageEditor = () => {

    const [errorResponseMessage, setErrorResponseMessage] = useState('');
    const [pageNames, setPageNames] = useState<any>(null);
    const [selectedOption, setSelectedOption] = useState<OptionType | null>(null);
    const [pageContentValue, setPageContentValue] = useState('');


    useEffect(() => {
        getPageNames().then((response: any) => {
            if (response.status === 200) {
                const pageData = response.data.data;
                setPageNames(pageData);
            }
        });
    }, []);

    interface OptionType {
        label: string;
        value: string;
    }

    const PageOptionsList = pageNames?.map((item: { PageID: number; PageName: string; }) => ({ value: item.PageID, label: item.PageName }));

    const navigate = useNavigate();
    type FormData = {
        PageID: string;
        PageContent: string;
    };
    const {
        handleSubmit,
    } = useForm<FormData>();

    const handleChange = (selectedOption: OptionType | null) => {

        const selectedPageDetail = pageNames.find((element: { PageID: string; }) => element.PageID === selectedOption?.value);
        setSelectedOption(selectedOption);
        setPageContentValue(selectedPageDetail.PageHTML);
    };
    const handleEditorChange = (data: string) => {
        setPageContentValue(data);
    };

    const onSubmit = async () => {
        if (pageContentValue === '' || selectedOption === null) {
            setErrorResponseMessage('Please select the field');
        } else {
            const requestData = {
                PageID: selectedOption?.value,
                PageHTML: pageContentValue,
            }
            const res = await saveMenuContent(requestData);
            if (res.status === 200) {
                window.location.reload();
            } else if (res.status === 409) {
                setErrorResponseMessage(res.data.message);
            }
        }
    };
    return (
        <>
            <HelmetProvider>
                <title>
                    Page Editor
                </title>
                <meta name="keywords" content="holistic health care, homeopathic queries, dr in ahmedabad, holistic healing, dr homeo, homeopathy doctor, homeopathic materia medica, natural health care, naturopathic remedies, healing with homeopathy, homoeopathic cases, homeo clinic , holistic health, Arthritis doctor ahmedabad, child homeopathy doctor" />
                <meta name="description" content="Dr Neha Pujara is a matured homoeopathic doctor.Homeopathic Patient any where in the world can post there homoeopathic query to her by providing basic detail." />
            </HelmetProvider>
            <div id="content_wrapper_inner">
                <div id="content" className="home">
                    <h1 className="fs-22">
                        Page Editor</h1>

                    <hr />
                    <br />
                    {errorResponseMessage && <p className="compulsary">{errorResponseMessage}</p>}
                    <form onSubmit={handleSubmit(onSubmit)}>

                        <div className="row mt10">
                            <div className="form-group col-md-9">
                                <Select
                                    options={PageOptionsList}
                                    value={selectedOption}
                                    onChange={handleChange}
                                    placeholder="Select..."
                                />
                            </div>
                        </div>

                        <div className="row mt10">

                            <div className="form-group col-md-12">
                            </div>
                        </div>
                        <div className="row mt10">
                            <div className="form-group col-md-12">
                                <CKEditorComponent placeholder="Add your notes/requirments here" data={pageContentValue} onChange={handleEditorChange} />

                            </div>
                        </div>
                        <div className="button_02">
                            <button type="submit" className="btnSubmit">Save</button>
                        </div>
                    </form>

                </div>
            </div>
        </>
    )

};
export default AdminPageEditor;
