import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import PublicRoute from "./PublicRoute";
import Home from "../pages/Home";
import FirstConsultation from "../pages/ClinicInformation/FirstConsultation";
import PatientGuidelines from "../pages/ClinicInformation/PatientGuidelines";
import ThingsToKeepInMind from "../pages/ClinicInformation/ThingsToKeepInMind";
import ClinicSchedule from "../pages/ClinicInformation/ClinicSchedule";
import CaseRecordForm from "../pages/ClinicInformation/CaseRecordForm";
import HolisticScience from "../pages/UnderstandingHomeopathy/HolisticScience";
import PhilosophyOfHomeopathy from "../pages/UnderstandingHomeopathy/PhilosophyOfHomeopathy";
import ConstitutionalApproach from "../pages/UnderstandingHomeopathy/ConstitutionalApproach";
import CaseTakingProcess from "../pages/UnderstandingHomeopathy/CaseTakingProcess";
import HowDoesHomeopathyWork from "../pages/UnderstandingHomeopathy/HowDoesHomeopathyWork";
import PreparationOfHomoeopathicMedicines from "../pages/UnderstandingHomeopathy/PreparationOfHomoeopathicMedicines";
import SourcesOfHomoeopathicMedicines from "../pages/UnderstandingHomeopathy/SourcesOfHomoeopathicMedicines";
import HomeopathyInGeneral from "../pages/ScopeOfHomeopathy/HomeopathyInGeneral";
import HomeopathyInchildren from "../pages/ScopeOfHomeopathy/HomeopathyInchildren";
import HomeopathyInSurgicalCases from "../pages/ScopeOfHomeopathy/HomeopathyInSurgicalCases";
import HomeopathyInPsychiatricDiseases from "../pages/ScopeOfHomeopathy/HomeopathyInPsychiatricDiseases";
import HomeopathyInSpecificDiseaseConditions from "../pages/ScopeOfHomeopathy/HomeopathyInSpecificDiseaseConditions";
import HomeopathyAsPreventiveMedicine from "../pages/ScopeOfHomeopathy/HomeopathyAsPreventiveMedicine";
import BenefitsOfHomoeopathicTreatment from "../pages/ScopeOfHomeopathy/BenefitsOfHomoeopathicTreatment";
import HomoeopathyInCancerCases from "../pages/ScopeOfHomeopathy/HomoeopathyInCancerCases";
import HomoeopathyInInjury from "../pages/ScopeOfHomeopathy/HomoeopathyInInjury";
import AllergicBronchitis from "../pages/DocumentedCases/AllergicBronchitis";
import ChalazionWithRecurrentStyes from "../pages/DocumentedCases/ChalazionWithRecurrentStyes";
import CrohnDiseaseWithInsomnia from "../pages/DocumentedCases/CrohnDiseaseWithInsomnia";
import HeadInjury from "../pages/DocumentedCases/HeadInjury";
import LichenPlanus from "../pages/DocumentedCases/LichenPlanus";
import Migraine from "../pages/DocumentedCases/Migraine";
import NocturnalEnuresisBedwetting from "../pages/DocumentedCases/NocturnalEnuresisBedwetting";
import PolycythemiaVera from "../pages/DocumentedCases/PolycythemiaVera";
import Psoriasis from "../pages/DocumentedCases/Psoriasis";
import PsychiatricDisease from "../pages/DocumentedCases/PsychiatricDisease";
import Warts from "../pages/DocumentedCases/Warts";
import GoutWithHysteria from "../pages/DocumentedCases/GoutWithHysteria";
import RheumatoidArthritis from "../pages/DocumentedCases/RheumatoidArthritis";
import TineaCapitis from "../pages/DocumentedCases/TineaCapitis";
import Queries from "../pages/Contact/Queries";
import ContactUS from "../pages/Contact/ContactUS";
import FAQs from "../pages/FAQs";
import PageNotFound from "../pages/PageNotFound";
import PrivateRoute from "./PrivateRoute";
import Login from "../pages/Login";
import AdminPageEditor from "../pages/ManagePages/AdminPageEditor";


export default function AppRoutes() {
  return (
    <Router>
      <Routes>

        <Route path="/" element={<PublicRoute />}>
          {<Route path="/" element={<Home />}></Route>}
          <Route path="Home" element={<Home />}></Route>
          <Route path="FAQs" element={<FAQs />}></Route>
          <Route path="Queries" element={<Queries />}></Route>
          <Route path="ContactUs" element={<ContactUS />}></Route>
          <Route path="Login" element={<Login />} />
          <Route path="*" element={<PageNotFound />} />
        </Route>

        <Route path="/ClinicInformation" element={<PublicRoute />}>
          <Route path="FirstConsultation" element={<FirstConsultation />}></Route>
          <Route path="PatientGuidelines" element={<PatientGuidelines />}></Route>
          <Route path="ThingsToKeepInMind" element={<ThingsToKeepInMind />}></Route>
          <Route path="ClinicSchedule" element={<ClinicSchedule />}></Route>
          <Route path="CaseRecordForm" element={<CaseRecordForm />}></Route>
        </Route>

        <Route path="/UnderstandingHomeopathy" element={<PublicRoute />}>
          <Route path="HolisticScience" element={<HolisticScience />}></Route>
          <Route path="PhilosophyOfHomeopathy" element={<PhilosophyOfHomeopathy />}></Route>
          <Route path="ConstitutionalApproach" element={<ConstitutionalApproach />}></Route>
          <Route path="CaseTakingProcess" element={<CaseTakingProcess />}></Route>
          <Route path="HowDoesHomeopathyWork" element={<HowDoesHomeopathyWork />}></Route>
          <Route path="PreparationOfHomoeopathicMedicines" element={<PreparationOfHomoeopathicMedicines />}></Route>
          <Route path="SourcesOfHomoeopathicMedicines" element={<SourcesOfHomoeopathicMedicines />}></Route>
        </Route>

        <Route path="/ScopeOfHomeopathy" element={<PublicRoute />}>
          <Route path="HomeopathyInGeneral" element={<HomeopathyInGeneral />}></Route>
          <Route path="HomeopathyInchildren" element={<HomeopathyInchildren />}></Route>
          <Route path="HomeopathyInSurgicalCases" element={<HomeopathyInSurgicalCases />}></Route>
          <Route path="HomeopathyInPsychiatricDiseases" element={<HomeopathyInPsychiatricDiseases />}></Route>
          <Route path="HomeopathyInSpecificDiseaseConditions" element={<HomeopathyInSpecificDiseaseConditions />}></Route>
          <Route path="HomeopathyAsPreventiveMedicine" element={<HomeopathyAsPreventiveMedicine />}></Route>
          <Route path="BenefitsOfHomoeopathicTreatment" element={<BenefitsOfHomoeopathicTreatment />}></Route>
          <Route path="HomoeopathyInCancerCases" element={<HomoeopathyInCancerCases />}></Route>
          <Route path="HomoeopathyInInjury" element={<HomoeopathyInInjury />}></Route>
        </Route>

        <Route path="/DocumentedCases" element={<PublicRoute />}>
          <Route path="AllergicBronchitis" element={<AllergicBronchitis />}></Route>
          <Route path="ChalazionWithRecurrentStyes" element={<ChalazionWithRecurrentStyes />}></Route>
          <Route path="CrohnDiseaseWithInsomnia" element={<CrohnDiseaseWithInsomnia />}></Route>
          <Route path="HeadInjury" element={<HeadInjury />}></Route>
          <Route path="LichenPlanus" element={<LichenPlanus />}></Route>
          <Route path="Migraine" element={<Migraine />}></Route>
          <Route path="NocturnalEnuresisBedwetting" element={<NocturnalEnuresisBedwetting />}></Route>
          <Route path="PolycythemiaVera" element={<PolycythemiaVera />}></Route>
          <Route path="Psoriasis" element={<Psoriasis />}></Route>
          <Route path="PsychiatricDisease" element={<PsychiatricDisease />}></Route>
          <Route path="Warts" element={<Warts />}></Route>
          <Route path="GoutWithHysteria" element={<GoutWithHysteria />}></Route>
          <Route path="RheumatoidArthritis" element={<RheumatoidArthritis />}></Route>
          <Route path="TineaCapitis" element={<TineaCapitis />}></Route>
        </Route>

        <Route path="/ManagePages" element={<PrivateRoute />}>
          <Route path="AdminPageEditor" element={<AdminPageEditor />}></Route>
        </Route>

      </Routes>
    </Router>
  );
}




