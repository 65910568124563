import { SubmitHandler, useForm } from "react-hook-form";
import { useState } from "react";
import { HelmetProvider } from "react-helmet-async";
import { login } from "../services/pages.service";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { loginAdmin } from "../store/actions/auth";

const Login = () => {

    const [successResponseMessage, setSuccessResponseMessage] = useState('');
    const [errorResponseMessage, setErrorResponseMessage] = useState('');

    const navigate = useNavigate();
    const dispatch = useDispatch();
    type FormData = {
        username: string;
        password: string;
    };
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<FormData>();

    const onSubmit: SubmitHandler<FormData> = (data) => {
        login(data).then((res) => {
            if (res.status === 200) {

                console.log('res', res);
                dispatch(loginAdmin(res.data.token));
                localStorage.setItem('token', res.data.token);

                setSuccessResponseMessage(res.data.message);
                navigate('/ManagePages/AdminPageEditor');
            }
            if (res.status === 409) {
                setErrorResponseMessage(res.data.message)
            }
        });
    };
    return (
        <>
            <HelmetProvider>
                <title>
                    Holistic Health Care - Homeopathic Treatment Queries
                </title>
                <meta name="keywords" content="holistic health care, homeopathic queries, dr in ahmedabad, holistic healing, dr homeo, homeopathy doctor, homeopathic materia medica, natural health care, naturopathic remedies, healing with homeopathy, homoeopathic cases, homeo clinic , holistic health, Arthritis doctor ahmedabad, child homeopathy doctor" />
                <meta name="description" content="Dr Neha Pujara is a matured homoeopathic doctor.Homeopathic Patient any where in the world can post there homoeopathic query to her by providing basic detail." />
            </HelmetProvider>
            <div id="content_wrapper_inner">
                <div id="content" className="home">
                    <h1 className="fs-22">
                        Login</h1>
                        <hr />
                    <br />
                    {errorResponseMessage && <p className="compulsary">{errorResponseMessage}</p>}
                    {successResponseMessage && <p className="text-success">{successResponseMessage}</p>}
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <table className="queryForm">
                            <tbody>
                                <tr>

                                </tr>

                                <tr>
                                    <td>
                                        Username
                                        <span className="compulsary">*</span>
                                        <p></p>
                                    </td>
                                    <td>
                                        <input
                                            {...register("username", { required: "Please enter username." })}
                                        />
                                        {errors.username && <p className="compulsary">{errors.username.message}</p>}
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        Password
                                        <span className="compulsary">*</span>
                                        <p></p>
                                    </td>
                                    <td>
                                        <input type="password"
                                            {...register("password", { required: "Please enter Password." })}
                                        />
                                        {errors.password && <p className="compulsary">{errors.password.message}</p>}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        &nbsp;
                                    </td>
                                    <td>
                                        <div className="button_02">
                                            <input type="submit" value="Submit" className="btnSubmit" />
                                        </div>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </form>

                </div>
            </div>
        </>
    )

};
export default Login;