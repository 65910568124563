export interface authStateData {
  token: string | null | undefined;
}
const initialState: authStateData = {
  token: localStorage.getItem('token'),
};

function AuthReducer(authState = initialState, action: { type: string; payload: any; }) {
  const { type, payload } = action;

  switch (type) {
    case "LOGIN":
      return payload;
    default:
      return authState;
  }
}

export default AuthReducer;
