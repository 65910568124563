//import ImageSlider from "../components/ImageSlider";
import { useEffect, useState } from 'react';
import { getPageContent } from '../../services/pages.service';
import { HelmetProvider } from 'react-helmet-async';

const HomeopathyInSpecificDiseaseConditions = () => {
    const [htmlElement, setHtmlElement] = useState<any>(null);
    useEffect(() => {
        getPageContent('Scope Of Homoeopathy In Specific Disease Conditions').then((response) => {
            if (response.status === 200) {
                setHtmlElement(response.data.data?.PageHTML);
            }
        });
    }, []);

    return (
        <>
            <HelmetProvider>
                <title>
                    Homeopathy in Specific Disease Conditions
                </title>
                <meta name="keywords" content="holistic health care, Scope of Homeopathy,Homeopathy in Specific Disease, dr in ahmedabad, holistic healing, dr homeo, homeopathy doctor, homeopathic materia medica, natural health care, naturopathic remedies, healing with homeopathy, homoeopathic cases, homeo clinic , holistic health, Arthritis doctor ahmedabad, child homeopathy doctor" />
                <meta name="description" content="Dr Neha Pujara is an efficient homeopathic doctor for Specific Disease Condition. She has a vast experience of 15 years in homeopathic consultation and practice in ahmedabad." />
            </HelmetProvider>
            <div id="content_wrapper_inner">
                <div id="content" className="home">
                    <div>
                        <br />
                        <h1 className='health_care_content'>
                            Scope of Homeopathy in Specific Disease Conditions
                        </h1>
                        <hr />
                        <br />
                        <br />
                        {htmlElement && (
                            <p dangerouslySetInnerHTML={{ __html: htmlElement }}></p>
                        )}
                    </div>
                </div>
            </div>
        </>
    )

};
export default HomeopathyInSpecificDiseaseConditions;