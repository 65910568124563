//import ImageSlider from "../components/ImageSlider";
import { useEffect, useState } from 'react';
import { getPageContent } from '../../services/pages.service';
import { Link } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const CaseRecordForm = () => {
    const [htmlElement, setHtmlElement] = useState<any>(null);
    useEffect(() => {
        getPageContent('Case Record Form').then((response) => {
            if (response.status === 200) {
                setHtmlElement(response.data.data?.PageHTML);
            }
        });
    }, []);

    return (
        <>
            <HelmetProvider>
                <Helmet>

                    <title>
                        Holistic Health Care - Homeopathic Case Record Form
                    </title>
                    <meta name="keywords" content="holistic health care, healing, dr in ahmedabad, holistic healing, dr homeo, homeopathy doctor, Case Record Form, natural health care, naturopathic remedies, healing with homeopathy, homoeopathic cases, homeo clinic , holistic health, Arthritis doctor ahmedabad, child homeopathy doctor" />
                    <meta name="description" content="Dr Neha Pujara is an efficient homoeopathic doctor at ahmedabad.She provides the online  Case Record Form to get the homeopathic patient detail." />
                </Helmet>
            </HelmetProvider>
            <div id="content_wrapper_inner">
                <div id="content" className="home">
                    <div>
                        <br />
                        <h1 className='health_care_content'>
                            Case Record Form
                        </h1>
                        <hr />
                        <br />
                        <br />
                        {htmlElement && (
                            <p dangerouslySetInnerHTML={{ __html: htmlElement }}></p>
                        )}
                        <Link to={require('../../content/Case_Record_Form_NehaPujara.pdf')} target="_blank">
                            <b>Download Case Record Form</b>
                        </Link>
                        <br />
                        <br />
                        <p>Click on above link to download the case record form. You can then print it, fill
                            it up and mail it to us</p>
                    </div>
                </div>
            </div>
        </>
    )

};
export default CaseRecordForm;