import React, { useState, useRef, useEffect } from "react";
import { getPageContent } from "../services/pages.service";
import { HelmetProvider } from "react-helmet-async";

const AccordionItem = (props: any) => {
    const contentEl = useRef<HTMLDivElement>(null);
    const contentEl1 = useRef<HTMLDivElement>(null);
    const { handleToggle, active, faq } = props;
    const { header, id, text } = faq;

    if (contentEl.current) {
        if (active === id) {
            contentEl.current.style.height = (contentEl.current.scrollHeight - 20) + 'px';
        } else {
            contentEl.current.style.height = '0px';
        }
    }
    if (contentEl1.current) {
        if (active === id) {
            contentEl1.current.style.height = (contentEl1.current.scrollHeight - 25) + 'px';
        } else {
            contentEl1.current.style.height = '0px';
        }
    }

    return (
        <span className="rc-accordion-card">
            <span className={`rc-accordion-toggle ${active === id ? 'active' : ''}`} onClick={() => handleToggle(id)}>
                <b className="rc-accordion-title">{header}</b>
            </span>
            <div ref={contentEl} className={`rc-collapse ${active === id ? 'show' : ''}`} >
                <div ref={contentEl1} className="rc-accordion-body">
                    <span dangerouslySetInnerHTML={{ __html: text }}></span>
                </div>
            </div>
        </span>
    )
}

const FAQs = () => {
    const [faqs, setFaqs] = useState<any>([]);
    const [active, setActive] = useState(null);

    useEffect(() => {
        getPageContent('Frequently Asked Questions').then((response) => {
            if (response.status === 200) {
                const champData = JSON.parse(response.data.data?.PageHTML);
                setFaqs(champData);
            }
        });
    }, []);

    const handleToggle = (index: React.SetStateAction<null>) => {
        if (active === index) {
            setActive(null);
        } else {
            setActive(index);
        }
    }

    return (
        <>
            <HelmetProvider>
                <title>
                    Homeopathic Treatment - Frequently Asked Questions
                </title>
                <meta name="keywords" content="holistic health care, healing, dr in ahmedabad, holistic healing, dr homeo, homeopathy doctor, homeopathic materia medica, natural health care, naturopathic remedies, healing with homeopathy, homoeopathic cases, homeo clinic , holistic health, Arthritis doctor ahmedabad, child homeopathy doctor" />
                <meta name="description" content="Dr Neha Pujara has done the vast study and listed the basic homeopathic Frequently Asked Questions." />
            </HelmetProvider>
            <div id="content_wrapper_inner">
                <div id="content" className="home">
                    <div>
                        <br />
                        <h1 className='health_care_content'>
                            Frequently Asked Questions
                        </h1>
                        <div id="dvLink">
                            <hr />
                            <br />
                            <br />

                            {faqs.map((faq: any, index: number) => {
                                return (
                                    <AccordionItem key={index} active={active} handleToggle={handleToggle} faq={faq} />
                                )
                            })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FAQs;