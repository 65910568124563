import axiosInstance from "../utils/axiosInstance";


export const getPageContent = (PageName: string) => {
    return axiosInstance
        .get(`pageContent/getPageContent?PageName=${PageName}`)
        .catch((err: any) => err);
};

export const getPageNames = () => {
    return axiosInstance
        .get(`pageContent/getPageNames`)
        .catch((err: any) => err);
};

export const getHeaderMenu = () => {
    return axiosInstance
        .get(`pageContent/getHeaderMenu`)
        .catch((err: any) => err);
};

export const addQueries = (data: any) => {
    return axiosInstance
        .post(`pageContent/addQueries`, data)
        .catch((err: any) => err);
};

export const saveMenuContent = (data: any) => {
    return axiosInstance
        .post(`pageContent/saveMenuContent`, data)
        .catch((err: any) => err);
};

export const login = (data: { username: string, password: string }) => {

    return axiosInstance
        .post("pageContent/signIn", data)
        .catch((err) => err.response.data);
};

