//import ImageSlider from "../components/ImageSlider";
import { useEffect, useState } from 'react';
import { getPageContent } from '../../services/pages.service';
import { HelmetProvider } from 'react-helmet-async';

const BenefitsOfHomoeopathicTreatment = () => {
    const [htmlElement, setHtmlElement] = useState<any>(null);
    useEffect(() => {
        getPageContent('Benefits Of Homoeopathic Medication').then((response) => {
            if (response.status === 200) {
                setHtmlElement(response.data.data?.PageHTML);
            }
        });
    }, []);

    return (
        <>
            <HelmetProvider>
                <title>
                    Benefits of Homeopathic Treatment
                </title>
                <meta name="keywords" content="holistic health care, Benefits of Homeopathic Medication, dr in ahmedabad, holistic healing, dr homeo, homeopathy doctor, homeopathic materia medica, natural health care, naturopathic remedies, healing with homeopathy, homoeopathic cases, homeo clinic , holistic health, Arthritis doctor ahmedabad, child homeopathy doctor" />
                <meta name="description" content="Dr Neha Pujara knows benefits Of Homoeopathic Treatment and to contribute to society, she has started her consultation practice in Ahmedabad – Gujarat, India. She has a experience of 15 years in homoeopathic consultation and practice." />
            </HelmetProvider>
            <div id="content_wrapper_inner">
                <div id="content" className="home">
                    <div>
                        <br />
                        <h1 className='health_care_content'>
                            Benefits Of Homoeopathic Medication
                        </h1>
                        <hr />

                        <br />
                        <br />
                        {htmlElement && (
                            <p dangerouslySetInnerHTML={{ __html: htmlElement }}></p>
                        )}
                    </div>
                </div>
            </div>
        </>
    )

};
export default BenefitsOfHomoeopathicTreatment;