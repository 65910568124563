import { combineReducers, configureStore } from "@reduxjs/toolkit";
import AuthReducer, { authStateData } from "./reducers/AuthReducer";
export interface rootState {
  auth: authStateData;
 }

const rootReducer = combineReducers({
  auth: AuthReducer,
});

export default configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }),
});