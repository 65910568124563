import { Link } from "react-router-dom";

const PageNotFound = () => {
    return (
        <>

            <div id="content_wrapper_inner">
                <div id="content" className="home">
                    <div>
                        <br />
                        <div className="image404">
                            <img src={require('../assets/images/404.png')} alt="404" />
                            <div>
                                <Link to={'/'}><b>Back to Home</b></Link>
                            </div>

                        </div>
                        {/* <p>
                            The Page you are looking for might have been removed had its name changed or is temporarily unavailable.</p> */}

                        <div className="">


                        </div>

                    </div>
                </div>
            </div>
        </>
    )

};
export default PageNotFound;