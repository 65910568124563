//import ImageSlider from "../components/ImageSlider";
import { useEffect, useState } from 'react';
import { getPageContent } from '../../services/pages.service';
import { HelmetProvider } from 'react-helmet-async';

const ContactUS = () => {
    const [htmlElement, setHtmlElement] = useState<any>(null);
    useEffect(() => {
        getPageContent('Contact Us').then((response) => {
            if (response.status === 200) {
                setHtmlElement(response.data.data?.PageHTML);
            }
        });
    }, []);

    return (
        <>
            <HelmetProvider>
                <title>
                    Homeopathic, Homeopathy Treatment, Dr. Neha Pujara, Ahmedabad, Gujarat, INDIA, USA
                </title>
                <meta name="keywords" content="holistic health care, contact us, dr in ahmedabad, holistic healing, dr homeo, homeopathy doctor, homeopathic materia medica, natural health care, naturopathic remedies, healing with homeopathy, homoeopathic cases, homeo clinic , holistic health, Arthritis doctor ahmedabad, child homeopathy doctor" />
                <meta name="description" content="Dr Neha Pujara is ahmedabad based homeopathic doctor. She is running Holistic Health Care clinic in ahmedabad. Homeopathic patient can Contact her at Holistic Health Care between monday to friday" />
            </HelmetProvider>
            <div id="content_wrapper_inner">
                <div id="content" className="home">
                    <div>
                        <br />
                        <h1 className='health_care_content'>
                            Contact Us
                        </h1>
                        <hr />

                        <div>
                            <br />
                            <div className="contactDetails">
                                <br />
                                {htmlElement && (<span dangerouslySetInnerHTML={{ __html: htmlElement }}></span>)}
                            </div>
                            <div className="mapBox">
                                <iframe title="This is a unique title"
                                    src="https://maps.google.com/maps?f=q&amp;source=s_q&amp;hl=en&amp;geocode=&amp;q=Premier+apartment,Sandesh+press+road,++Vastrapur,++Ahmedabad,+Gujarat,+India&amp;aq=&amp;sll=37.0625,-95.677068&amp;sspn=34.808514,86.572266&amp;ie=UTF8&amp;hq=Premier+apartment,Sandesh&amp;hnear=20,+Smita+Apt,+Nr+radio+Mirchi+tower,+Times+of+India+Press+road,+shyamal,+satellite,+Ahmedabad-15,+Satellite+Rd,+Ahmedabad,+Gujarat,+India&amp;ll=23.037244,72.522586&amp;spn=0.07897,0.169086&amp;t=m&amp;z=13&amp;iwloc=A&amp;cid=17357781048491280882&amp;output=embed"
                                    width="425"
                                    height="350"
                                    allowFullScreen={true}
                                    aria-hidden="false"
                                    tabIndex={0}
                                />
                                <br />
                                <small><a href="https://maps.google.com/maps?f=q&amp;source=embed&amp;hl=en&amp;geocode=&amp;q=Premier+apartment,Sandesh+press+road,++Vastrapur,++Ahmedabad,+Gujarat,+India&amp;aq=&amp;sll=37.0625,-95.677068&amp;sspn=34.808514,86.572266&amp;ie=UTF8&amp;hq=Premier+apartment,Sandesh&amp;hnear=20,+Smita+Apt,+Nr+radio+Mirchi+tower,+Times+of+India+Press+road,+shyamal,+satellite,+Ahmedabad-15,+Satellite+Rd,+Ahmedabad,+Gujarat,+India&amp;ll=23.037244,72.522586&amp;spn=0.07897,0.169086&amp;t=m&amp;z=13&amp;iwloc=A&amp;cid=17357781048491280882"
                                >View Larger Map</a></small>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )

};
export default ContactUS;