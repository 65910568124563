//import ImageSlider from "../components/ImageSlider";
import { useEffect, useState } from 'react';
import { getPageContent } from '../../services/pages.service';
import { HelmetProvider } from 'react-helmet-async';

const TineaCapitis = () => {
    const [htmlElement, setHtmlElement] = useState<any>(null);
    useEffect(() => {
        getPageContent('Tinea Capitis').then((response) => {
            if (response.status === 200) {
                setHtmlElement(response.data.data?.PageHTML);
            }
        });
    }, []);

    return (
        <>
            <HelmetProvider>
                <title>
                    Tinea Capitis - Homeopathic Case
                </title>
                <meta name="keywords" content="holistic health care, Tinea Capitis, dr in ahmedabad, holistic healing, dr homeo, homeopathy doctor, homeopathic materia medica, natural health care, naturopathic remedies, healing with homeopathy, homoeopathic cases, homeo clinic , holistic health, Arthritis doctor ahmedabad, child homeopathy doctor" />
                <meta name="description" content="Dr Neha Pujara is an efficient homoeopathic consultant in healing Tinea Capitis. She has done her homoeopathic graduation from Pune University in 1996. After her graduation she has started her consultation practice in Ahmedabad – Gujarat state, India. She has a vast experience of 15 years in homoeopathic consultation and practice." />
            </HelmetProvider>
            <div id="content_wrapper_inner">
                <div id="content" className="home">
                    <div>
                        <br />
                        <h1 className='health_care_content'>
                            A case of a 30 year old lady with Tinea Capitis
                        </h1>
                        <hr />

                        <div>
                            <div className="tineaCapitisImage">
                                <br />

                                <img src={require("../../assets/images/HairLossBefore.png")} alt="Hair lose before" />
                                <img src={require("../../assets/images/HairLossAfter.png")} alt="Hair lose after" />
                                <br />
                                <b>Case of tinea capitis</b>
                                <br />
                                <br />
                                {htmlElement && (
                                    <p dangerouslySetInnerHTML={{ __html: htmlElement }}></p>
                                )}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )

};
export default TineaCapitis;