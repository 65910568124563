import { Link } from "react-router-dom";

const Header = () => {
  return (
    <>
      {/* Header */}
      <div id="header_wrapper">
        <div id="header">
          <div className="header_left">
            <div id="site_title">
              <Link to={'/'}>
                <img src={require("../assets/images/Holistic Health.png")} alt="Holistic Health Care" />
              </Link>
            </div>
          </div>
          {/* end of header left  */}
        </div>
      </div>

      {/* End Header */}
    </>
  );
};

export default Header;
